angular.module("app")
    .factory('_flashService', function (_appStorage) {

        var map = _appStorage.get('flashService') || {};

        function saveMap() {
            _appStorage.set('flashService', map);
        }

        return {
            put: function (key, value) {
                map[key] = value;
                saveMap();
            },
            get: function (key) {
                var result = map[key];
                delete map[key];
                saveMap();
                return result;
            }
        };
    });